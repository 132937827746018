/* stylelint-disable scss/dollar-variable-pattern */
@use "sass:math";
@use "sass:color";
@use "~rfs/scss" as rfs;

/* Limits */
$CONTENT_WIDTH: 51.5625rem;
$MAX_WIDTH: 90rem;
$NAVBAR_HEIGHT: 6rem;
$FOOTER_HEIGHT: 15rem;

/* Default section heights: */
$LINK_SECTION_HEIGHT: 12rem;

/* Device sizes */
$device-sm: 40rem;
$device-md: 71rem;
$device-lg: 87.5rem;

/* Spacing */
$space-s: 0.5rem;
$space-m: 1rem;
$space-l: 1.5rem;
$space-xl: 2rem;
$space-xxl: 4.5rem;
$space-xxxl: 7.5rem;

/* Font sizes
 * @TODO: replace occurences in actual front-end with mixins
 */
$font-size-xs: 0.75rem;
$font-size-s: 0.875rem;
$font-size-ms: 0.95rem;
$font-size-m: 1.125rem;
$font-size-ml: 1.25rem;
$font-size-l: 1.5rem;
$font-size-xl: 2rem;
$font-size-xxl: 2.75rem;
$font-size-xxxl: 4.5rem;
$font-size-xxxxl: 6.5rem;

/* Font families */
$font-primary: "Signifier", serif;
$font-secondary: "Graphik", sans-serif;

/* Colors */
$color-brand: #ea6b0e;
$color-brand-2: #ea4705;
$color-dark: #111213;
$color-white: #fff;
$color-fund: #ea6b0e;
$color-org: #53acde;
$color-org-2: #8478e0;
$color-base: #efefef;
$color-near-white: #f8fbff;
$color-light: #c4c4c4;
$color-lighter: #e0e4e9;
$color-on: #35dca0;
$color-off: #f49855;
$color-error: #f01414;
$color-dark-blue: #0083cf;
$color-disabled: #696f8c;
$color-dark-light: #393f49;
$color-slate: #adbacc;
$color-dark-slate: #969fad;
$color-gray: #5e6775;
$color-off-light: #f2f3f6;
$color-light-orange: #ffecdcc2;
$color-purple: #627eea;
$color-purple-gray: #afa6c9;
$color-dark-green: #03814d;
$color-dark-red: #9e0142;
$color-logo-blue: #0e243e;
$color-light-border: rgba($color-purple-gray, 0.5);
$color-border: $color-slate;

/* Border radius */
$border-radius-s: 0.5rem;
$border-radius-m: 1.25rem;
$border-radius-xxl: 6.25rem;

/* Shadow */
$box-shadow-light: 0 4px 16px 0 #00000005;
$box-shadow-medium: 0 18px 43px rgb(75 51 142 / 6%);
$box-shadow-heavy: 0 4px 16px 0 $color-dark;
$box-shadow-light-orange: 0 10px 30px 0 rgba(color.mix($color-fund, $color-slate, $weight: 90%), 0.05);
$box-shadow-medium-orange: 0 18px 26px rgb(234 107 14 / 12%);
$box-shadow-medium-red: 0 18px 26px rgba(244, 39, 39, 12%);
$box-shadow-light-blue: 0 10px 30px 0 rgba(color.mix($color-org, $color-dark-light, $weight: 85%), 0.05);
$box-shadow-medium-blue: 0 18px 26px rgb(83 172 222 / 12%);
$box-shadow-light-purple: 0 10px 30px 0 rgba($color-purple, 0.05);
$box-shadow-medium-purple: 0 10px 30px 0 rgba($color-purple, 0.1);
$screenshot-shadow: rgb(33 51 109 / 32%) 0 60px 123px -25px, rgb(5 51 91 / 8%) 0 35px 75px -35px;
$screenshot-shadow-filter: drop-shadow(0 60px 123px -25px rgb(33 51 109 / 32%)),
  drop-shadow(0 35px 75px -35px rgb(5 51 91 / 8%));
$token-shadow: drop-shadow(0 124.59px 80.3279px rgba(106, 92, 179, 15%))
  drop-shadow(0 52.0508px 33.5591px rgba(106, 92, 179, 10.8%))
  drop-shadow(0 27.8288px 17.9423px rgba(106, 92, 179, 8.94%))
  drop-shadow(0 15.6006px 10.0583px rgba(106, 92, 179, 7.5%))
  drop-shadow(0 8.2854px 5.3419px rgba(106, 92, 179, 6.06%))
  drop-shadow(0 3.4473px 2.2228px rgba(106, 92, 179, 4.22%));

// Transition time
$transition-s: 0.2s;
$transition-m: 0.5s;
$transition-l: 0.8s;

/* Animations */
$slide-animation: slide 5s linear infinite;

@keyframes slide {
  0% {
    left: -100%;
  }

  100% {
    // left: 0;
  }
}

$dash-animation: dash 100s linear infinite;

@keyframes dash {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 1000;
  }
}

$spin-animation: spin 2s cubic-bezier(0.47, -0.01, 0.13, 1) forwards;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  30% {
    transform: rotate(-36deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Rotate */
$rotate-animation: rotate 26s linear forwards infinite;

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

$float-animation: floating 4s ease-in-out infinite;

@keyframes floating {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-3px);
  }

  100% {
    transform: translateY(0);
  }
}

/* Font mixins */
@mixin title($level: 1) {
  font-weight: 400;
  font-family: Signifier, Georgia, "Times New Roman", Times, serif;
  font-style: normal;
  line-height: 105%;
  color: $color-dark;

  @if $level == 1 {
    @include rfs.font-size(3rem);

    @media (max-width: $device-md) {
      @include rfs.font-size(2.75rem);
    }
  } @else if $level == 2 {
    @include rfs.font-size(2.625rem);

    @media (max-width: $device-md) {
      @include rfs.font-size(2.325rem);
    }
  } @else {
    line-height: 115%;

    @include rfs.font-size(1.85rem);

    @media (max-width: $device-md) {
      @include rfs.font-size(1.5rem);
    }
  }
}

@mixin header($level: 1) {
  font-weight: 500;
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-style: normal;
  line-height: 112.5%;

  @if $level == 1 {
    @include rfs.font-size(2.25rem);

    @media (max-width: $device-md) {
      @include rfs.font-size(2rem);
    }
  } @else if $level == 2 {
    line-height: 125%;

    @include rfs.font-size(1.5rem);

    @media (max-width: $device-md) {
      @include rfs.font-size(1.25rem);
    }
  } @else if $level == 3 {
    font-weight: 400;
    line-height: 125%;

    @include rfs.font-size(1.25rem);

    @media (max-width: $device-md) {
      @include rfs.font-size(1rem);
    }
  } @else {
    font-weight: 400;
    line-height: 145%;

    @include rfs.font-size(1.125rem);

    @media (max-width: $device-md) {
      @include rfs.font-size(0.825rem);
    }
  }
}

@mixin paragraph($big: false) {
  font-weight: 400;
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-style: normal;
  line-height: 140%;
  letter-spacing: 0.01rem;

  @include rfs.font-size(0.875rem);

  @media (max-width: $device-md) {
    @include rfs.font-size(0.75rem);
  }

  @if $big {
    line-height: 135%;

    @include rfs.font-size(1rem);

    @media (max-width: $device-md) {
      @include rfs.font-size(0.85rem);
    }
  }
}

@mixin label {
  font-weight: 400;
  font-family: Graphik, Arial, Helvetica, sans-serif;
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.02rem;
  text-transform: capitalize;
  color: $color-slate;

  @include rfs.font-size(0.75rem);
}

@mixin text-link {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    opacity: 0.9;
  }
}

@mixin max-width {
  width: 100%;
  max-width: $MAX_WIDTH;
  margin-right: auto;
  margin-left: auto;

  @include rfs.padding-left(32px);
  @include rfs.padding-right(32px);
}

@mixin button-section-height {
  max-height: $LINK_SECTION_HEIGHT;

  @media (max-width: $device-md) {
    max-height: unset;
  }
}

@mixin icon-grid {
  display: grid;
  grid-template: auto / 1fr 1fr;
  gap: 0 50px;

  @media (max-width: $device-sm) {
    @include rfs.padding(12px);
    grid-template: auto / 1fr;
    gap: 8px;
  }

  & > div {
    display: inline-flex;
    align-items: center;
    max-width: 400px;

    svg {
      @include rfs.margin(22px 30px);
      flex: 0 0 45px;
      width: 45px;
      height: 45px;
      object-fit: contain;

      @media (max-width: $device-md) {
        @include rfs.margin(12px 36px);
      }
    }

    p {
      @include paragraph($big: true);
      max-width: 300px;
      font-weight: 400;
      text-align: left;
      color: $color-dark-light;
    }
  }
}

@mixin cta-hero {
  @include rfs.margin-top(64px);
  @include rfs.padding-bottom(160px);
  @include rainbow-gradient-background("bottom", false, 300px);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: max-content;

  & > div:nth-child(2) {
    @include rfs.margin-top(48px);
    @include screenshot-image;

    $w: min(60vw, calc(#{$MAX_WIDTH} * 0.6));
    position: relative;
    z-index: 0;
    aspect-ratio: 1 / 2;
    width: $w;
    height: calc($w / 2);

    &::after {
      position: absolute;
      right: -100px;
      bottom: -100px;
      left: -100px;
      z-index: 0;
      display: inline-block;
      height: 200px;
      background: linear-gradient(rgb(255 255 255 / 0%), white 50%);
      content: " ";
    }

    img {
      object-fit: cover;
      object-position: top;
    }

    @media (max-width: $device-sm) {
      width: 80vw;
      height: 40vw;
      overflow: hidden;
    }
  }

  & > div:nth-child(3) {
    @include rfs.rfs(48px, gap);
    @include rfs.margin-top(56px);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $device-md) {
      @include rfs.padding(0 24px 72px);
      flex-direction: column;
    }

    h1 {
      @include title(3);
      @include rfs.padding-right(48px);
      position: relative;
      flex: 0 1 500px;
      margin-top: 0;
      border-right: 1px solid $color-light-border;
      text-align: right;
      color: $color-dark-light;

      @media (max-width: $device-md) {
        @include rfs.padding-bottom(32px);
        @include rfs.padding-right(0);
        flex: 1;
        border-right: none;
        border-bottom: 1px solid $color-light-border;
        text-align: center;
      }
    }

    a {
      @include rfs.margin-right(18px);
      height: fit-content;

      path {
        fill: $color-brand;
      }

      @media (max-width: $device-md) {
        @include rfs.margin-right(0);
      }
    }
  }
}

@mixin rainbow-gradient-background($position: "top", $flip: false, $height: 160px) {
  $l-r: linear-gradient(
    270deg,
    #62c3ff 0%,
    #c0bcff 25.52%,
    #d7cbf2 43.62%,
    #ead2de 50.42%,
    #ffc2cb 59.64%,
    #ffc2cb 75%,
    #f8d2be 100%
  );
  $r-l: linear-gradient(
    90deg,
    #62c3ff 0%,
    #c0bcff 25.52%,
    #d3cbf2 43.62%,
    #ead2de 50.42%,
    #ffc2cb 59.64%,
    #ffc2cb 75%,
    #f8d2be 100%
  );
  position: relative;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100% 1px;

  @if $flip {
    background-image: if($position == "top", $r-l, $l-r);
  } @else {
    background-image: if($position == "top", $l-r, $r-l);
  }

  @if $position == "bottom" {
    background-position: bottom;
  }

  &::after {
    position: absolute;
    left: 0;

    @if $position == "bottom" {
      bottom: 0;
    } @else {
      top: 0;
    }
    z-index: -1;
    display: inline-block;
    width: 100%;
    height: $height;
    background: radial-gradient(23.78% 73.32% at 32.64% 0%, #fff5f7 39.91%, rgb(255 249 249 / 0%) 100%),
      radial-gradient(87.23% 87.23% at 10.42% 5.11%, #fff5f1 3.54%, rgb(249 253 255 / 0%) 100%),
      radial-gradient(25.76% 82.75% at 67.05% 0%, #fbf8ff 0%, rgba(247, 243, 255, 72.9%) 28.66%, rgba(252, 249, 255, 0%) 100%),
      radial-gradient(100% 100% at 100% 0%, #e7f6ff 0%, rgb(249 253 255 / 0%) 100%);

    @if $position == "bottom" {
      transform: scaleY(-100%) scaleX(-100%);
    }
    content: " ";

    @if $flip {
      @if $position == "bottom" {
        transform: scaleY(-100%);
      } @else {
        transform: scaleX(-100%);
      }
    }
  }
}

@mixin side-fade-gradients($width: 50px, $light-color: rgba(255, 255, 255, 1), $dark-color: rgba(255, 255, 255, 0)) {
  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    width: $width;
    background-image: linear-gradient(to left, $dark-color, $light-color 90%);
    content: "";
    pointer-events: none;

    @media (max-width: $device-md) {
      display: none;
    }
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 15;
    width: $width;
    background-image: linear-gradient(to right, $dark-color, $light-color 90%);
    content: "";
    pointer-events: none;

    @media (max-width: $device-md) {
      display: none;
    }
  }
}

@mixin screenshot-image {
  border: 10px solid rgba($color-purple, 0.1);
  border-radius: 24px;
  box-shadow: $box-shadow-medium;

  img {
    border-radius: 14px;
  }
}

@mixin coming-soon($content: "Coming Soon", $absolute: false, $condition: "after") {
  display: flex;
  align-items: center;

  @if $absolute == true {
    position: relative;
  }

  &::#{$condition} {
    @include label;
    flex: 0;
    border-radius: 55px;
    padding: 5px 10px;
    font-size: 11px;
    font-weight: 600;
    font-style: normal;
    line-height: 15px;
    white-space: nowrap;
    color: $color-white;
    background-color: $color-org;
    box-shadow: 0 4px 8px rgba($color-org, 0.22);
    content: $content;

    @if $absolute == true {
      position: absolute;
    }
  }
}

/* Standard card properties */
$card-content-padding: 0 0.45rem;

@mixin card-footer {
  @include label;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  border-top: 1px solid $color-lighter;
  padding: $card-content-padding;
  padding-top: 0.65rem;
  transition: $transition-m;

  em {
    font-style: normal;
    color: $color-fund;
  }
}

@mixin card-hover($spin: true) {
  transition: $transition-m;

  &:hover {
    box-shadow: $box-shadow-medium;
    transform: translateY(-12px);

    /* TODO: Remove (https://www.w3schools.com/cssref/sel_attr_contain.asp) */

    [class*="avatar"]:not([class*="avatar-container"]) {
      transform: translateY(-7px);

      @if $spin {
        svg {
          animation: $spin-animation;
        }
      } @else {
        box-shadow: $box-shadow-medium;
      }
    }
  }
}

@mixin card-row-button {
  display: flex;
  gap: 1.25rem;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.75rem;
  border-radius: 16px;
  padding: $space-m $space-l;
  letter-spacing: 0.02rem;
  color: $color-dark-light;
  box-shadow: $box-shadow-light;
  cursor: pointer;
  transition: $transition-m;

  svg {
    width: 40px;
    height: 40px;
  }

  &:hover {
    border-color: $color-fund;
    background-color: rgba($color-fund, 0.05);
    box-shadow: $box-shadow-medium;
    transform: translateY(-4px);
  }
}

/* Common styles
 * TODO: properly replace with mixins
 */

@mixin rainbow-number {
  font-size: 4rem;
  font-weight: bold;
  font-family: $font-secondary;
  text-shadow: 0 5px 10px #c1bbd134;
  background: linear-gradient(rgb(207 207 207 / 8%), rgb(212 212 212 / 8%)),
    linear-gradient(150deg, #53acde 5%, #7164e2 40%, #eb4a4a 55%, #ea6b0e 85%);
  background-clip: text;
  animation: shine 5s ease-in-out infinite alternate;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;

  @media (min-width: $device-md) {
    font-size: $font-size-xxxl;
  }

  @keyframes shine {
    @for $i from 0 through 100 {
      $percent: $i + 0%;
      $quarter-percent: math.div($i, 2);
      #{$percent} {
        background: linear-gradient(rgb(207 207 207 / 8%), rgb(212 212 212 / 8%)),
          linear-gradient(
            150deg,
            rgb(83 171 222 / 100%) #{$quarter-percent - 35%},
            rgb(123 112 228) #{$quarter-percent + 15%},
            rgb(235 74 74) #{$quarter-percent + 35%},
            rgb(234 106 14 / 100%) #{$quarter-percent + 55%}
          );
        background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
      }
    }
  }
}

@mixin list-table($header: "default") {
  @include label;
  width: 100%;
  text-align: left;

  th {
    font-weight: normal;
    text-transform: uppercase;

    @if $header == "camelCase" {
      padding-bottom: 0.25rem;
      font-size: $font-size-ms;
      font-weight: bold;
      text-transform: capitalize;
      color: $color-slate;
      opacity: 0.75;
    }
  }

  td {
    padding: $space-s 0;
    color: $color-dark;

    &:not(:last-child) {
      padding-right: 1rem;
    }

    span {
      display: inline-flex;
      gap: $space-s;
      align-items: center;
    }
  }
}
