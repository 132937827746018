@use "libs/styles/src/lib/variables";
@use "rfs/scss" as rfs;

.ein-lookup {
  @include rfs.padding(50px 0);
  display: flex;
  align-self: center;
  border: 1px solid variables.$color-lighter;
  border-radius: 26px;
  box-shadow: variables.$box-shadow-light;

  @media (max-width: variables.$device-md) {
    flex-flow: column;
  }

  h3 {
    @include variables.header(2);
    @include rfs.margin-bottom(16px);
    margin-top: 0;
    color: variables.$color-dark-light;
  }

  p {
    @include variables.paragraph(true);
    max-width: 300px;
    color: variables.$color-dark-light;
  }

  input {
    @include rfs.padding(16px);
    @include rfs.margin-bottom(16px);
    border: 1px solid variables.$color-lighter;
    border-radius: 8px;
    color: variables.$color-slate;
  }

  hr {
    width: 1px;
    border: none;
    background-color: variables.$color-lighter;

    @media (max-width: variables.$device-md) {
      @include rfs.margin(50px 0);
      align-self: center;
      width: 80%;
      height: 1px;
    }
  }

  & > div {
    @include rfs.padding(0 50px);
    display: inline-flex;
    flex-basis: 50%;
    flex-direction: column;
    text-align: left;
  }
}

.ein-lookup-button {
  @include rfs.padding(8px 20px);
  @include rfs.margin-right(16px);
}
