@use "libs/styles/src/lib/variables";
@use "rfs/scss" as rfs;

.callout-container {
  @include variables.rainbow-gradient-background($flip: true);
  @include rfs.padding-top(160px);
  text-align: center;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    display: inline-block;
    background: radial-gradient(152.47% 50% at 50% 50%, rgba(255, 255, 255, 0%) 0%, #fff 100%),
      radial-gradient(28.52% 99.44% at 15.24% 53.42%, #ffece7 0%, rgba(255, 255, 255, 0%) 100%),
      radial-gradient(45.26% 161.48% at 30.28% 54.74%, #ffe4eb 0%, rgba(255, 255, 255, 0%) 100%),
      radial-gradient(27.55% 68.49% at 45.31% 60.32%, #e5e2ff 0%, rgba(255, 255, 255, 0%) 100%),
      radial-gradient(67.53% 106.84% at 53.47% 60.6%, #c8ebff 0%, rgba(255, 255, 255, 0%) 100%),
      #fff;
    opacity: 0.15;
    content: " ";

    @media (max-width: variables.$device-md) {
      opacity: 0.25;
    }
  }
}

.callout-inner {
  @include variables.rainbow-gradient-background("bottom", true);
  @include rfs.padding-bottom(40px);
}

.ndao-callout {
  @include rfs.padding-bottom(86px);
  display: flex;
  justify-content: center;
  align-content: center;

  & > div {
    display: flex;
    gap: 28px;
    align-items: center;
    filter: variables.$token-shadow;

    & > div {
      & > span {
        position: relative;

        & > svg {
          margin-top: 2px;
          animation: variables.$rotate-animation;

          circle {
            stroke-width: 2px;
          }
        }

        &:first-child {
          z-index: 1;
          margin-right: -42px;
        }
      }
    }

    @media (max-width: variables.$device-md) {
      flex-flow: column;
    }
  }

  h4 {
    @include rfs.font-size(3rem);
    margin: auto 0;
    font-weight: 700;
    font-family: "GT Haptik", monospace;
    letter-spacing: -0.05em;
    color: variables.$color-logo-blue;
    opacity: 0.8;
  }

  h5 {
    @include variables.header(1);
    @include rfs.font-size(1.5rem);
    align-self: center;
    font-weight: 400;
    line-height: 1;
    color: variables.$color-dark-light;
    transform: translateY(1px);
    opacity: 0.8;
  }

  hr {
    @include rfs.margin-left(50px);
    @include rfs.margin-right(50px);
    min-width: 1px;
    border: none;
    background-color: variables.$color-org-2;
    opacity: 0.25;

    @media (max-width: variables.$device-md) {
      @include rfs.margin-top(50px);
      @include rfs.margin-bottom(50px);
      height: 1px;
    }
  }

  img {
    transform: translateY(0.5rem);
  }

  @media (max-width: variables.$device-md) {
    @include rfs.padding-left(16px);
    @include rfs.padding-right(16px);
    flex-flow: column;
  }
}

.learn-more-button {
  @include rfs.padding(16px);
  gap: 8px;
  justify-content: center;
  align-content: center;
  align-self: center;
  margin: 0 auto;
  font-size: 1rem;
  font-weight: 500;
  color: #393f49;

  svg {
    color: #53acde;
  }
}
