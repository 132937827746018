@use "libs/styles/src/lib/variables";
@use "rfs/scss" as rfs;

.image-button-row {
  @include rfs.padding-bottom(64px);
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    @include variables.header(2);
    @include rfs.margin-top(80px);
    @include rfs.margin-bottom(32px);
    text-align: center;
    color: rgba(variables.$color-purple, 0.5);
    opacity: 1;
  }

  & > div {
    display: flex;
    flex-direction: row;
    gap: 50px;
    justify-content: space-evenly;

    & > hr {
      width: 1px;
      border: none;
      background-color: variables.$color-lighter;
    }

    @media (max-width: variables.$device-md) {
      @include rfs.padding(24px);
      flex-direction: column;
      gap: 20px;
      align-items: center;
      padding: 0;

      & > hr {
        display: none;
      }
    }
  }
}

.image-button {
  height: fit-content;

  svg path {
    fill: variables.$color-fund;
    transition: variables.$transition-s;
  }

  &:first-child {
    svg {
      path {
        fill: variables.$color-brand-2;
      }
    }
  }

  &:last-child {
    svg {
      path {
        fill: variables.$color-org;
      }
    }
  }

  @media (max-width: variables.$device-md) {
    width: 100%;
  }
}
