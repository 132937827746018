@use "libs/styles/src/lib/variables";
@use "rfs/scss" as rfs;

.text-grid-container {
  @include variables.max-width;
  @include rfs.padding-top(52px);
  @include rfs.padding-bottom(52px);
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  text-align: center;

  & > svg {
    opacity: 0.18;
    animation: variables.$rotate-animation;
  }

  & > h3 {
    @include variables.title(3);
    @include rfs.margin-bottom(120px);
    max-width: 825px;
    color: variables.$color-dark-light;
    @media (max-width: variables.$device-md) {
      @include rfs.margin-bottom(86px);
    }
  }
}

.text-grid {
  display: grid;
  grid-auto-rows: 1fr;
  grid-template: 1fr / 1fr 1fr;
  gap: 64px;

  @include rfs.padding(0 64px);

  @media (max-width: variables.$device-md) {
    @include rfs.padding(0);
    display: flex;
    flex-direction: column;
    gap: 42px;
  }
}

.pill {
  @include rfs.margin-top(64px);
  @include rfs.margin-bottom(32px);
}

.text-item {
  text-align: left;

  h5 {
    @include variables.header(2);
    @include rfs.padding-left(4px);
    color: variables.$color-dark-light;
  }

  hr {
    @include rfs.margin(16px 0);
    height: 1px;
    border: none;
    background-color: rgba(variables.$color-purple-gray, 0.5);
  }

  p {
    @include rfs.margin-bottom(24px);
    @include rfs.padding-left(4px);
    @include variables.paragraph($big: true);
    color: variables.$color-disabled;
  }

  & > *:last-child {
    @include rfs.margin-left(-4px);
  }
}

.coming-soon {
  @include variables.coming-soon;

  &::after {
    @include rfs.margin-left(10px);
  }
}
