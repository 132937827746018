@use "libs/styles/src/lib/variables";
@use "rfs/scss" as rfs;

.stats-card {
  display: grid;
  grid-template: auto / 1fr 1fr 1fr 1fr;
  align-self: center;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: variables.$device-sm) {
    grid-template: auto / 1fr 1fr;

    @include rfs.margin(0 12px -72px);
  }

  div {
    @include rfs.padding(4px 32px 8px);
    text-align: center;

    &:not(:last-child) {
      border-right: 1px solid variables.$color-lighter;
    }

    @media (max-width: variables.$device-sm) {
      @include rfs.padding(16px 32px 8px);

      &:nth-child(2) {
        border-right: none;
      }

      &:nth-child(1),
      &:nth-child(2) {
        @include rfs.padding-top(8px);
        @include rfs.padding-bottom(16px);
        border-bottom: 1px solid variables.$color-lighter;
      }
    }
  }

  h3 {
    @include variables.header;
    margin: 0;

    @include rfs.margin-bottom(2px);
    font-weight: 600;
    color: variables.$color-dark-light;
  }

  span {
    @include variables.paragraph;
    color: variables.$color-purple;
  }
}
